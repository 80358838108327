import ModalOverlay from "../../ModalOverlay/ModalOverlay";
import { ReactNode, useContext } from "react";
import ModalContext from "../../../../contexts/modal-context";
import Button from "../../../UI/Button/Button";
import { useTranslation } from "react-i18next";

type Props = {
    status: "success" | "warning" | "error" | "info";
    message: ReactNode;
    onConfirm?: () => void;
    link?: ReactNode;
    confirmButtonText?: string;
    closeButtonText?: string;
};

const AlertOverlay = ({
    status,
    message,
    onConfirm,
    link,
    confirmButtonText,
    closeButtonText,
}: Props) => {
    const { t } = useTranslation();
    const { closeModal } = useContext(ModalContext);

    let title = t("modal.success");
    let titleClass = "text-success";
    if (status === "error") {
        title = t("modal.error");
        titleClass = "text-danger";
    } else if (status === "warning") {
        title = t("modal.warning");
        titleClass = "text-dark";
    } else if (status === "info") {
        title = t("modal.info");
        titleClass = "text-dark";
    }

    const confirmHandler = () => {
        onConfirm!();
        closeModal();
    };

    return (
        <ModalOverlay>
            <h6 className={`text-capitalize ${titleClass}`}>{title}</h6>
            <hr />
            <p className="form-text text-black">{message}</p>
            <hr />
            <div className="d-flex justify-content-end gap-2">
                <Button className="btn btn-sm btn-dark" onClick={closeModal}>
                    {closeButtonText || t("modal.close")}
                </Button>
                {link}
                {onConfirm && (
                    <Button
                        className="btn btn-sm btn-primary"
                        onClick={confirmHandler}
                    >
                        {confirmButtonText || t("modal.confirm")}
                    </Button>
                )}
            </div>
        </ModalOverlay>
    );
};

export default AlertOverlay;
