import BalanceSettings from "./BalanceSettings/BalanceSettings";
import React, { useContext, useEffect, useState } from "react";
import {
    FailureResponse as UpdateAccountFailureResponse,
    updateAccount as api_updateAccount,
} from "../../../../api/users/updateAccount";
import AlertOverlay from "../../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../../contexts/modal-context";
import PayoutBalanceSettings from "./PayoutBalanceSettings/PayoutBalanceSettings";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import CurrencySettings from "./CurrencySettings/CurrencySettings";
import { ApiErrorCode } from "../../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import AuthContext from "../../../../contexts/auth-context";
import AcceptPledgesSettings from "./AcceptPledgesSettings/AcceptPledgesSettings";
import BecomeCreatorSettings from "./BecomeCreatorSettings/BecomeCreatorSettings";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../../SettingsPage";

const AccountSettingsPage = () => {
    const { sc, scrollMarginTop } = useOutletContext<OutletContextType>();
    const { user, refreshUser } = useContext(AuthContext);
    const { showModal } = useContext(ModalContext);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        refreshUser();
    }, []);

    const updateAccount = async (payment_settings?: {
        currency?: string;
        accepts_pledges?: boolean;
    }) => {
        setIsLoading(true);
        try {
            await api_updateAccount(payment_settings);
            refreshUser();
        } catch (error) {
            const { errors, status_code, code } =
                error as UpdateAccountFailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else {
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    }
                } else console.log(errors);
            }
        }
        setIsLoading(false);
    };

    return (
        <div className="d-flex flex-column gap-3">
            {isLoading && <Spinner className="text-dark" />}
            {!user?.account!.payment_settings.currency && (
                <div className="border rounded-3 p-3">
                    {isLoading && <Spinner className="text-dark" />}
                    <CurrencySettings
                        isLoading={isLoading}
                        updateAccount={(currency: string) =>
                            updateAccount({
                                currency,
                            })
                        }
                    />
                </div>
            )}
            {user?.account!.payment_settings.currency && (
                <>
                    <div className="border rounded-3 p-3">
                        {isLoading && <Spinner className="text-dark" />}
                        <BalanceSettings />
                    </div>
                    {user?.is_creator && (
                        <div className="border rounded-3 p-3">
                            {isLoading && <Spinner className="text-dark" />}
                            <PayoutBalanceSettings />
                        </div>
                    )}
                </>
            )}
            {user?.is_creator &&
                (!user.account!.payment_settings.can_accept_pledges ||
                    !user.account!.payment_settings.accepts_pledges) && (
                    <div className="border rounded-3 p-3">
                        {isLoading && <Spinner className="text-dark" />}
                        <AcceptPledgesSettings account={user.account!} />
                    </div>
                )}
            {!user?.is_creator && (
                <div
                    id="become-creator"
                    className={`border rounded-3 p-3 ${
                        sc === "become-creator" &&
                        "border-3 border-primary-subtle"
                    }`}
                >
                    <BecomeCreatorSettings />
                </div>
            )}
            {/*<div className="border rounded-3 p-3">*/}
            {/*    <ResetPasswordSettings />*/}
            {/*</div>*/}
        </div>
    );
};

export default AccountSettingsPage;
