import React, { useContext } from "react";
import ProfileImageForm from "./ProfileImageForm/ProfileImageForm";
import ProfileInfoForm from "./ProfileInfoForm/ProfileInfoForm";
import InvitationLinkForm from "./InvitationLinkForm/InvitationLinkForm";
import AuthContext from "../../../../contexts/auth-context";
import ProfileAboutForm from "./ProfileAboutForm/ProfileAboutForm";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../../SettingsPage";

const ProfileSettingsPage = () => {
    const { sc, scrollMarginTop } = useOutletContext<OutletContextType>();
    const { user } = useContext(AuthContext);

    return (
        <div className="d-flex flex-column gap-3">
            <div className="border rounded-3 p-3">
                <ProfileImageForm />
            </div>
            <div
                id="info"
                className={`border rounded-3 p-3 ${
                    sc === "info" && "border-3 border-primary-subtle"
                }`}
                style={{ scrollMarginTop: scrollMarginTop }}
            >
                <ProfileInfoForm />
            </div>
            {user?.is_creator && (
                <>
                    <div
                        id="about"
                        className={`border rounded-3 p-3 ${
                            sc === "about" && "border-3 border-primary-subtle"
                        }`}
                        style={{ scrollMarginTop: scrollMarginTop }}
                    >
                        <ProfileAboutForm />
                    </div>
                    <div
                        id="invitation"
                        className={`border rounded-3 p-3 ${
                            sc === "invitation" &&
                            "border-3 border-primary-subtle"
                        }`}
                    >
                        <InvitationLinkForm />
                    </div>
                </>
            )}
        </div>
    );
};

export default ProfileSettingsPage;
