import { SlPencil } from "react-icons/sl";
import { AiOutlineYoutube } from "react-icons/ai";
import { RiUserReceivedLine, RiUserSharedLine } from "react-icons/ri";
import { MdOutlineVerified } from "react-icons/md";

import { Link, useNavigate } from "react-router-dom";
import User from "../../../models/User";
import blank_profile_image from "../../../images/blank-profile-picture.svg";
import {
    FailureResponse,
    followUser as api_followUser,
} from "../../../api/users/followUser";
import { unfollowUser as api_unfollowUser } from "../../../api/users/unfollowUser";
import React, { useContext, useEffect, useState } from "react";
import Button from "../../../components/UI/Button/Button";
import AlertOverlay from "../../../components/Modal/overlays/AlertOverlay/AlertOverlay";
import ModalContext from "../../../contexts/modal-context";
import { ApiErrorCode } from "../../../constants";
import EmailNotVerifiedErrorOverlay from "../../../components/Modal/overlays/EmailNotVerifiedErrorOverlay/EmailNotVerifiedErrorOverlay";
import { Trans, useTranslation } from "react-i18next";
import { parseProfileImageSrc } from "../../../utils/helpers";

type Props = {
    user: User;
};

const ProfilePageHeader = ({ user }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { showModal, closeModal } = useContext(ModalContext);
    const [isFollowing, setIsFollowing] = useState(user.am_i_follower);
    const [descriptionVisible, setDescriptionVisible] = useState(false);

    useEffect(() => {
        setIsFollowing(user.am_i_follower);
        const af = new URLSearchParams(window.location.search).get("af");
        if (!user.is_myself && !user.am_i_follower && af) {
            showModal(
                <AlertOverlay
                    status="info"
                    message={
                        <Trans
                            i18nKey="modal.wouldLikeToFollow"
                            components={[<strong />]}
                            values={{ name: user.name }}
                        />
                    }
                    onConfirm={async () => {
                        await followUnfollowUser();
                    }}
                    confirmButtonText={t("profilePage.header.followBtn")}
                    closeButtonText={t("modal.cancel")}
                />
            );
        }
    }, [user]);

    const followUnfollowUser = async () => {
        try {
            if (isFollowing) await api_unfollowUser(user.uuid);
            else await api_followUser(user.uuid);
            setIsFollowing((prevState: boolean) => !prevState);
        } catch (error) {
            const { errors, status_code, code } = error as FailureResponse;
            if (errors) {
                if (status_code === 403) {
                    if (code === ApiErrorCode.EMAIL_NOT_VERIFIED) {
                        showModal(
                            <EmailNotVerifiedErrorOverlay
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                    } else
                        showModal(
                            <AlertOverlay
                                status="error"
                                message={
                                    errors.error instanceof Array
                                        ? errors.error[0]
                                        : errors.error!
                                }
                            />
                        );
                } else console.log(errors);
            }
        }
    };

    const followButtonClickHandler = async () => {
        await followUnfollowUser();
    };

    let followButton = <></>;
    if (!user.is_myself) {
        if (isFollowing) {
            followButton = (
                <Button
                    className="btn btn-sm btn-secondary w-100"
                    onClick={followButtonClickHandler}
                    title={t("profilePage.header.unfollowBtn")}
                >
                    {t("profilePage.header.followingBtn")}
                </Button>
            );
        } else {
            followButton = (
                <Button
                    className="btn btn-sm btn-primary w-100"
                    onClick={followButtonClickHandler}
                    title={t("profilePage.header.followBtn")}
                >
                    {t("profilePage.header.followBtn")}
                </Button>
            );
        }
    }

    let image_src: string = blank_profile_image;
    if (user.profile_image)
        image_src = parseProfileImageSrc(user.profile_image);

    return (
        <div>
            <div className="d-flex justify-content-between border-bottom pb-3">
                <div className="d-flex gap-3">
                    <div className="d-flex flex-column gap-2 align-items-center">
                        <img
                            src={image_src}
                            alt="profile"
                            width="100"
                            height="100"
                            className="rounded-circle border border-1"
                        />
                        {followButton}
                        {user.is_creator && (
                            <Button
                                className="btn btn-sm btn-danger w-100"
                                onClick={() => {
                                    navigate(`/start?a=${user.username}`);
                                }}
                                title={t("navbar.startPetition")}
                            >
                                {t("navbar.startPetition")}
                            </Button>
                        )}
                    </div>
                    <div className="d-flex gap-1 flex-column">
                        <div className="d-flex align-items-center gap-1">
                            <div className="d-flex flex-wrap gap-1 fs-5 fw-bold">
                                <span>{user.name}</span>
                            </div>
                            {user.is_account_verified && (
                                <MdOutlineVerified
                                    className="text-primary"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={t("userBasicInfo.verifiedAccount")}
                                />
                            )}
                            {user.is_placeholder && (
                                <MdOutlineVerified
                                    className="text-secondary"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title={t(
                                        "userBasicInfo.verifiedPlaceholderAccount"
                                    )}
                                />
                            )}
                        </div>
                        <div className="">@{user.username}</div>
                        <div className="d-flex flex-wrap">
                            <div className="mb-1 me-3">
                                <Link
                                    to="following"
                                    className="btn btn-link text-decoration-none text-black px-0 d-flex align-items-center gap-1"
                                    title={t("profilePage.header.following")}
                                >
                                    <RiUserSharedLine />
                                    <span>{user.profile!.following_count}</span>
                                </Link>
                            </div>
                            <div>
                                <Link
                                    to="followers"
                                    className="btn btn-link text-decoration-none text-black px-0 d-flex align-items-center gap-1"
                                    title={t("profilePage.header.followers")}
                                >
                                    <RiUserReceivedLine />
                                    <span>{user.profile!.followers_count}</span>
                                </Link>
                            </div>
                        </div>
                        {user.is_creator && (
                            <div className="d-flex flex-column gap-2">
                                {user.profile!.headline && (
                                    <p className="m-0 p-0">
                                        {user.profile!.headline}
                                    </p>
                                )}
                                {user.is_myself && !user.profile!.headline && (
                                    <Link
                                        to="/settings/profile?sc=about"
                                        className="btn btn-link text-decoration-none text-danger d-flex gap-2 align-items-center p-0"
                                    >
                                        <span>
                                            {t(
                                                "profilePage.header.pleaseAddHeadline"
                                            )}
                                        </span>
                                        <SlPencil />
                                    </Link>
                                )}
                                {descriptionVisible && (
                                    <p
                                        className="m-0 p-0"
                                        style={{ whiteSpace: "pre-line" }}
                                    >
                                        {user.profile!.description}
                                    </p>
                                )}
                                {user.profile!.description && (
                                    <div>
                                        <button
                                            className="btn btn-link text-decoration-none p-0"
                                            onClick={() =>
                                                setDescriptionVisible(
                                                    !descriptionVisible
                                                )
                                            }
                                        >
                                            {descriptionVisible
                                                ? `...${t(
                                                      "profilePage.header.less"
                                                  )}`
                                                : `...${t(
                                                      "profilePage.header.more"
                                                  )}`}
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        {user.is_creator && user.profile!.youtube && (
                            <div className="d-flex gap-3 align-items-center">
                                <a
                                    href={user.profile!.youtube}
                                    className="link-danger"
                                    target="_blank"
                                    rel="noreferrer"
                                    title="YouTube"
                                >
                                    <AiOutlineYoutube />
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                {user.is_myself && (
                    <div className="d-flex flex-column">
                        <Link
                            to="/settings/profile"
                            className="btn btn-link text-secondary py-0"
                            title={t("profilePage.header.editProfile")}
                        >
                            <SlPencil />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfilePageHeader;
